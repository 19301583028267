import Vue from 'vue';
import Router from 'vue-router';
import uploads from '@/components/uploads.vue';
import HelloWorld from '@/components/HelloWorld.vue';
Vue.use(Router);

export default new Router({
  mode: 'history', // 使用 HTML5 History 模式
  routes: [
    {
      path: '/uploads',
      name: 'uploads',
      component: uploads
    },
    {
        path: '/HelloWorld',
        name: 'HelloWorld',
        component: HelloWorld
    }
  ]
});
