<template>
    <div>
        <h1>生成url链接</h1>
        <el-upload
            :action="VUE_APP_API_URL + '/upload'"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :before-upload="beforeAvatarUpload"
            :on-success="handleAvatarSuccess"
        >
            <i class="el-icon-plus"></i>
        </el-upload>
        <div v-for="(item,index) in fileList" :key="index">
            {{ 'https://www.shaozongren.space/node/'+item.response.file.filename }}
        </div>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: 'uploads',
  created(){
    console.log('uploads',process.env.VUE_APP_API_URL)
  },
  data() {
      return {
        dialogImageUrl: '',
        dialogVisible: false,
        VUE_APP_API_URL: process.env.VUE_APP_API_URL,
        fileList: []
      };
    },
    methods: {
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePictureCardPreview(file) {
        console.log(file,'sasdasd');
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      beforeAvatarUpload(file) {
        const isLt2M = file.size  / 1024 / 1024 < 100;
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 100MB!');
        }
        return isLt2M;
      },
      handleAvatarSuccess(res, file,fileList) {
        console.log(res, file,this.imgageUrl,fileList);
        this.fileList = null;
        this.fileList = fileList;
        // this.imageUrl = URL.createObjectURL(file.raw);
      },
    }
}
</script>